<!-- eslint-disable no-unused-vars -->
<template>
  <v-card class="boxItem">
    
    <v-card-title>{{ job.job_name }}</v-card-title>
    <v-card-subtitle>
      {{ job.file_name }} 
    </v-card-subtitle>
    <v-card-text>
      
      {{ this.job.code }}  {{ getCodeError(this.job.code)}}
      <br><br>
      <v-chip text-color="black" prepend-icon="mdi-calendar-start">
        {{ beginDate }}
      </v-chip>

      <v-chip v-if="this.job.status == 3 || this.job.status == 5" text-color="black" prepend-icon="mdi-timelapse">
        {{ this.duration }}
      </v-chip>

      <v-chip v-if="this.job.status == 3 || this.job.status == 5" text-color="black" prepend-icon="mdi-calendar-end">
        {{ completedDate }}
      </v-chip>
      <v-chip
        :color="this.color"
        text-color="black"
        prepend-icon="mdi-progress-check"
      >
        {{ this.status }}
      </v-chip>

    </v-card-text>
    <v-card-actions v-if="this.job.status === 1">
        <v-chip
          color="red"
          @click="deleteTask"
        >
          Eliminar
        </v-chip>
     </v-card-actions>    
  </v-card>
</template>

<script>
/* eslint-disable */
import { taskDeleteService } from "@/services/taskService";
import errorCodes from './taskError';
import router from "@/router";
export default {
  name: "TaskBox",
  props: {
    job: Object,
  },
  components: {},
  setup() {},
  mounted() {
    this.status = this.getStatusString(this.job.status);
    this.color = this.getColor(this.job.status);
    this.duration = this.getDuration();
    this.code = this.job.code;
    console.log(this.job)
  },
  data: () => ({
    status: "",
    color: "",
    duration: 0,
  }),
  methods: {
    getStatusString(status) {
      if (status == 2) {
        if (this.closedByRTH) {
          return "Cerrado por RTH";
        } 
      }
      const s = [
        "Pendiente de ejecución",
        "En ejecución",
        "Completado",
        "Cancelado",
        "Error",
        "Pausada",
      ];
      return s[status - 1];
    },
    getColor(status) {
      const s = ["blue", "yellow", "green", "gray", "red", "yellow"];
      return s[status - 1];
    },
    getCodeError(code) {
      return errorCodes[code];
    },
    getDuration() {
      let r = new Date(this.job.completed_time) - new Date(this.job.begin_time);
      r = r / 1000;
      let minutes = Math.floor(r / 60);
      let seconds = r - minutes * 60;
      if (minutes > 0) {
        return `${minutes}m ${seconds}s`;
      } else {
        return `${seconds}s`;
      }
    },
    async deleteTask() {
      await taskDeleteService(this.job.job_id);
      router.go();
    },
  },
  computed: {
    
    beginDate() {
      try {
        const ds = this.job.begin_time.split(" ");
        const ds1 = ds[0].split("-");
        const ds2 = ds[1].split(":");
        //const d = Date(Date.UTC(ds1[0], ds1[1], ds1[2], ds2[3], ds2[4], ds2[5]));
        //console.log(d)
        const d = Date.parse(ds[0] + "T" + ds[1] + ".000Z");
        return new Date(d).toLocaleString();
        //return new Date(d).toLocaleString();

      }
      catch (error) {
        return null;
      }
    },
    completedDate() {
      try {
        const ds = this.job.completed_time.split(" ");
        console.log(ds)
        const ds1 = ds[0].split("-");
        const ds2 = ds[1].split(":");
        //console.log(ds1, ds2)
        //const d = Date(Date.UTC(ds1[0], ds1[1]-1, ds1[2], ds2[3], ds2[4], ds2[5]));
        //console.log(ds)
        //console.log(d)
        const d = Date.parse(ds[0] + "T" + ds[1] + ".000Z");
        return new Date(d).toLocaleString();
      } catch (error) {
        return null;
      }
    },
    closedByRTH() {
      try {
        const ds = this.job.begin_time.split(" ");
        const d = Date.parse(ds[0] + "T" + ds[1] + ".000Z");
        const now = new Date()
        let r = now - d;
        r = r / 1000;
        let minutes = Math.floor(r / 60);
        if (minutes > 40) {
          return true
        } else {
          return false
        }

      } catch (e) {
        console.log(e)
        return true
      }
    }
  },

};
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.boxItem {
  margin: 0px;
  padding: 10px;
}
</style>
