<template>
        <!--
        <v-row v-if="showVideoPanel"> 
            <v-col>
                <v-select density=compact v-model="deviceSelected" :items="liveCapacity" item-title="name"
                    :hint="deviceSelected?.hint" item-value="sn" label="Dispositivo" return-object single-line>
                </v-select>
            </v-col>
            <v-col>
                <v-select density=compact v-if="deviceSelected !== null" v-model="cameraSelected"
                    :items="getDeviceSelected()?.props.cameraList" item-title="name" item-value="idx" label="Cámara"
                    return-object single-line>
                </v-select>
            </v-col>
        </v-row>
        -->
        <div class="container-buttons" v-if="videoUrl !== null && showVideoPanel">
            <!--
            <v-btn class="container-buttons" @click="startLive" color="green"><v-icon>mdi-play</v-icon></v-btn>
            <v-btn class="container-buttons" @click="stopLive" color="error"><v-icon>mdi-stop</v-icon></v-btn>
            -->
            <div class="flex align-center justify-center mt-5"> {{ getVideoTypeString() }} </div>


            <v-btn class="container-buttons" @click="toggleVideoType"
                color="blue-grey-lighten-1"><v-icon>mdi-swap-horizontal-bold</v-icon></v-btn>

                <LentControl :videoId="this.videoId" />

            <!-- <v-btn v-if="getCameraIndex == '53-0-0'" class="container-buttons" @click="toggleActions" color="grey-lighten-1"><v-icon>mdi-cog</v-icon></v-btn> -->
            <v-btn class="container-buttons" @click="toggleActions"
                color="grey-lighten-1"><v-icon>mdi-camera-control</v-icon></v-btn>

        </div>
        <!--
        <div v-if="showActions && showVideoPanel">
            <v-row class="container-buttons">
                <v-col>
                    <LentControl :videoId="this.videoId" />
                </v-col>
            </v-row>
            -->
            <!-- 
            <v-row>
                <v-col>
                    <div v-if="this.lentSelection == 'zoom'">
                        <GimbalControl :device_sn="this.device_sn" :payloadIndex="this.cameraSelected.index"
                            lentType="zoom" />
                    </div>
                    <div v-else>
                        <GimbalControl :device_sn="this.device_sn" :payloadIndex="this.cameraSelected.index"
                            lentType="wide" />
                    </div>

                </v-col>
                <v-col v-if="this.lentSelection == 'zoom'">
                    <ZoomControl :maxZoom="200" :minZoom="2" :device_sn="this.device_sn"
                        :payloadIndex="this.cameraSelected.index" lentType="zoom" />
                </v-col>
                <v-col v-if="this.lentSelection == 'ir'">
                    <ZoomControl :maxZoom="20" :minZoom="2" :device_sn="this.device_sn"
                        :payloadIndex="this.cameraSelected.index" lentType="ir" />
                </v-col>
            </v-row>
        </div>
    -->

   <div v-if="showVideoPanel" class="flex flex-row w-full mt-6 h-2  items-center" @click="this.showVideoPanel=!this.showVideoPanel" >
    <div class="flex flex-col w-1/2 h-5 pr-40"  > <v-img size="10px" src="/logo_cw.svg"></v-img> </div>
    <div class="flex flex-col w-1/2" > 
        <v-icon class="ic" size="x-large">mdi-menu-up</v-icon>
    </div>
   </div>

   <div v-else class="flex flex-row w-full mt-6 h-2  items-center" @click="this.showVideoPanel=!this.showVideoPanel" >
    <div class="flex flex-col w-1/2 h-5 pr-40"  > <v-img size="10px" src="/logo_cw.svg"></v-img> </div>
    <div class="flex flex-col w-1/2" > 
        <v-icon class="ic" size="x-large">mdi-menu-down</v-icon>
    </div>
   </div>
    <!--
        <v-row v-if="showVideoPanel">
            <v-btn class="cont" @click="this.showVideoPanel=!this.showVideoPanel" block>
                <v-img class="im" src="/logo_cw.svg"></v-img>
                    <v-icon class="ic" size="x-large">mdi-menu-up</v-icon>
            </v-btn>


          
        </v-row>
        <v-row v-else>
                <v-btn class="cont" @click="this.showVideoPanel=!this.showVideoPanel" block>
                    <v-img class="im" src="/logo_cw.svg"></v-img>
                    <v-icon class="ic" size="x-large">mdi-menu-down</v-icon>
                </v-btn>
        </v-row>
        -->
</template>

<script>
//
import { getLiveCapacityService, startLiveService, stopLiveService, setLiveQualityService } from "@/services/videoService.js";
import { payloadAuthService, cameraIamService } from "@/services/payloadService.js";
import useStore from "@/store";
import { storeToRefs } from "pinia";
import useUiStore from "@/ui";
//import GimbalControl from './widgets/gimbalControl.vue';
//import ZoomControl from './widgets/zoomControl.vue';
import LentControl from './widgets/lentControl.vue';
import { CURRENT_CONFIG } from '@/api/http/config';
const VIDEO_RTMP = CURRENT_CONFIG.videriVideoRtmp;
const VIDEO_VIEW = CURRENT_CONFIG.videriVideoViewer;
const VIDEO_INFERENCE_VIEW = CURRENT_CONFIG.videriVideoInferenceViewer;
const CAMERA = CURRENT_CONFIG.camera
export default {
    name: "TSAVideoPanel",
    components: {
        //GimbalControl,
        //ZoomControl,
        LentControl
    },
    setup() {
        const { videoUrl, child_device_sn, device_sn, lentSelection } = storeToRefs(useStore());
        const { showVideoPanel } = storeToRefs(useUiStore());
        const uiStore = useUiStore();
        const deviceSelected = localStorage.getItem("child_device_sn")
        return {
            videoUrl,
            child_device_sn,
            device_sn,
            lentSelection,
            showVideoPanel,
            uiStore,
            deviceSelected: {
                "sn": deviceSelected
            }
        };
    },
    data: () => ({
        liveCapacity: [],
        //deviceSelected: {
        //    "sn": "1581F5BMD233W001C54L"
        //},
        //cameraSelected: null,
        cameraSelected: {
            //"index": "53-0-0",
            //"index": "53-0-0",
            "index": CAMERA
        },
        videoSelected: null,
        videoType: "detections",
        showActions: false,
    }),
    methods: {
        setVideoUrl() {
            if (this.videoType == "detections") {
                this.videoUrl = VIDEO_INFERENCE_VIEW + this.videoType + "/" + this.deviceSelected.sn + "_" + this.cameraSelected.index + "_" + "normal" + "-0" + "?user=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password");
            } else {
                this.videoUrl = VIDEO_VIEW + this.videoType + "/" + this.deviceSelected.sn + "_" + this.cameraSelected.index + "_" + "normal" + "-0" + "?user=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password");
            }
        },
        async toggleActions() {
            this.uiStore.showDroneControl = false;
            const r = await this.getPayloadAuth();
            console.log(r)
            this.lentSelection = null;
            this.showActions = !this.showActions;
            this.uiStore.toggleFloatVisibility("control-actions")
            
        },
        getVideoTypeString() {
            if (this.videoType == "original") {
                return "Video Original";
            } else {
                return "Video con detecciones";
            }
        },
        toggleVideoType() {
            if (this.videoType == "original") {
                this.videoType = "detections";
                this.videoUrl = VIDEO_INFERENCE_VIEW + this.videoType + "/" + this.deviceSelected.sn + "_" + this.cameraSelected.index + "_" + "normal" + "-0" + "?user=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password");

            } else {
                this.videoType = "original";
                this.videoUrl = VIDEO_VIEW + this.videoType + "/" + this.deviceSelected.sn + "_" + this.cameraSelected.index + "_" + "normal" + "-0" + "?user=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password");

            }
        },

        getCameraIndex() {
            try {
                return this.cameraSelected.index;
            }
            catch {
                return null;
            }
        },

        async getPayloadAuth() {
            const sn = this.device_sn;
            const payloadIndex = this.cameraSelected.index;
            const result = await payloadAuthService(sn, payloadIndex);
            console.log(result);
        },
        async moveCamera() {
            const sn = this.device_sn;
            const payloadIndex = this.cameraSelected.index;
            const result = await cameraIamService(sn, payloadIndex, "zoom", false, 0.4, 0.5);
            console.log(result);
        },
        async getLiveCapacity() {
            const video = await getLiveCapacityService();
            let result = [];

            if (video.code == 0) {
                for (let i = 0; i < video.data.length; i++) {
                    result.push(
                        {
                            name: video.data[i].name,
                            sn: video.data[i].sn,
                            id: i,
                            hint: video.data[i].name + " - " + video.data[i].sn,
                            props: {
                                cameraList: this.addCameraListIndex(video.data[i].cameras_list),
                            }
                        }
                    );
                }
                this.liveCapacity = result;
                //this.getVideoId();
            }

        },
        getStartVideoBody() {
            const username = localStorage.getItem("username");
            const password = localStorage.getItem("password");
            let rtmpURL = VIDEO_RTMP
            let videoId = this.deviceSelected.sn + "/" + this.cameraSelected.index + "/" + "normal-0"; //this.videoSelected + "-0"
            let videoPath = 'original/' + this.deviceSelected.sn + '_' + this.cameraSelected.index + '_' + "normal-0";//this.videoSelected + "-0"
            let liveURL = rtmpURL + videoPath + '?user=' + username + '&password=' + password;
            let livetypeSelected = 1;
            let claritySelected = 1;
            return {
                url: liveURL,
                video_id: videoId,
                url_type: livetypeSelected,
                video_quality: claritySelected
            }
        },

        getDeviceSelected() {
            try {
                return this.deviceSelected;
            } catch {
                return null;
            }
        },
        getCameraSelected() {
            try {
                return this.cameraSelected;
            } catch {
                return null;
            }
        },
        getVideoSelected() {
            try {
                return this.videoSelected;
            } catch {
                return null;
            }
        },
        addCameraListIndex(cameraList) {
            console.log(cameraList)
            for (let i = 0; i < cameraList.length; i++) {
                cameraList[i].idx = i;
            }
            return cameraList;
        },
        startLive() {
            const body = this.getStartVideoBody();
            startLiveService(body);
        },
        stopLive() {
            const body = this.getStartVideoBody();
            stopLiveService(body);
        },
        async setLiveQuality(videoId, claritySelected) {
            const data = {
                video_id: videoId,
                video_quality: claritySelected
            }
            await setLiveQualityService(data);
        },
        async getAllVideoId() {
            const data = this.liveCapacity;
            for (let i = 0; i < data.length; i++) {
                let device_sn = data[i].sn;
                for (let j = 0; j < data[i].props.cameraList.length; j++) {
                    let camera_idx = data[i].props.cameraList[j].index;
                    let video_id = device_sn + "/" + camera_idx + "/" + "normal" + "-0";
                    await this.setLiveQuality(video_id, 1);
                }
            }
        }
    },
    computed: {
        videoId() {
            try {
                const data = this.getStartVideoBody();
                return data.video_id;
            }
            catch {
                return null;
            }
        },
        showActionsButton() {
            if (this.videoId !== null) {
                return true;
            } else {
                return false;

            }
        }
        //
    },
    watch: {
        deviceSelected() {
            this.cameraSelected = null;
            this.videoUrl = null;
        },
        cameraSelected(value) {
            this.videoSelected = null;
            if (value == null) {
                this.videoUrl = null;
            } else {
                if (this.videoType == "original") {
                    this.videoUrl = VIDEO_VIEW + this.videoType + "/" + this.deviceSelected.sn + "_" + this.cameraSelected.index + "_" + "normal" + "-0" + "?user=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password");
                } else {
                    this.videoUrl = VIDEO_INFERENCE_VIEW + this.videoType + "/" + this.deviceSelected.sn + "_" + this.cameraSelected.index + "_" + "normal" + "-0" + "?user=" + localStorage.getItem("username") + "&password=" + localStorage.getItem("password");
                }
            }

        },
    },
    created() {
        //
    },
    mounted() {
        //
        //this.getLiveCapacity();
        this.setVideoUrl();
    },
};
</script>

<style scoped>

.cont {
    display: flexbox;
    width: 100%;
}

.im {
    width: 100px;
    position: relative;
    margin: auto;
    left: -275px;
}

.im2 {
    width: 100px;
    position: relative;
    margin-left: -50%;
    align-self: start;
    position: fixed;
}

.ic {
    position: flex;
    margin: auto;
    left: -50%;
}

.container-buttons {
    display: flexbox;
    justify-content: center;
    align-items: top;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

.containerControl {
    position: absolute;
    width: 100px;
    height: 100px;
    background-color: #353535;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-top: 0px;
    margin-bottom: 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.out {
    position: relative;
    width: 150px;
    height: 150px;
    background-color: #4b4b4b;
    border-radius: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: antiquewhite;
    border-width: 2px;
}

.innerCircle {
    position: absolute;
    width: 85px;
    height: 85px;
    background-color: #1d1d1d;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;

}

.mainCircle {
    position: relative;
    width: 45px;
    height: 45px;
    background-color: #111111;
    border-radius: 50%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;

}


.innerRow {
    position: absolute;
    width: 5px;
    height: 150px;
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.innerCol {
    position: absolute;
    width: 150px;
    height: 5px;
    background-color: #353535;
    border-radius: 0%;
    display: flex;
    margin: 0px 0px;
    border-color: antiquewhite;
    border-width: 2px;
}

.center-elements {
    align-items: center;
}
</style>
```