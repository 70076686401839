import axios from 'axios';
import { HMIN_CONFIG, HMAX_CONFIG } from '@/api/http/config'

export const getFlightConfig = async (dock_sn) => {
    try {
        const url = 'https://plugins.cvis.mindlabs.cl' + "/flight_config/" + dock_sn;
        const response = await axios.get(url, {
            timeout: 10000,
            headers: {
                'Content-Type': 'application/json',
            }}
        );
        HMIN_CONFIG.value = response.data.flight_min;
        HMAX_CONFIG.value = response.data.flight_max;

    } catch (error) {
        console.error('Error fetching flight config:', error);
        throw error;
    }
};