import mqtt from "mqtt";
import useStore from "@/store";
import { handleMessage, handleConnect } from "./handler";
import { notify } from "notiwind";
import { CURRENT_CONFIG } from '@/api/http/config'

class MQTTClient {
  constructor() {}

  connect() {
    const store = useStore();
    const options = {
      protocolVersion: 5,
      path: "/mqtt",
      username: localStorage.getItem("mqtt_username"),
      password: localStorage.getItem("mqtt_password"),
      clean: true,
      clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
      reconnectPeriod: 1000,
      connectTimeout: 5 * 1000,
    };
    console.log(options)
    const client = mqtt.connect(
      CURRENT_CONFIG.videriMqtt,
      //"mqtts://mqtt.testcloud.mindlabs.cl:443",
      //"mqtts://mqtt.alfa.cvis.videri.ai:443",
      options
    );
    client.on("connect", this._onConnect);
    client.on("close", this._onClose);
    client.on("error", this._onError);
    client.on("message", this._onMessage);
    client.on("reconnect", this._onReconnect);   
    store.setMQTTClient(client);
  }

  disconnect(e) {
    console.log("MQTT DISCONNECT")
    console.log(e)
    const store = useStore();
    store.mqttDisconnected = true;
    //const store = useStore();
    //store.mqtt.client.end();
    //store.setMQTTClient(null);
    notify(
      {
        group: 'workspace',
        level: 'warn',
        title: 'MQTT ',
        text: 'Cliente MQTT desconectado.',
      },
      2500 // 2.5s
    )
  }

  _onConnect() {
    const store = useStore();
    if (store.mqttDisconnected) {
      store.mqttDisconnected = false;
      notify(
        {
          group: 'workspace',
          level: 'success',
          title: 'MQTT ',
          text: 'Cliente MQTT conectado.',
        },
        2500 // 2.5s
      )
      handleConnect();
    }
    console.log("MQTT CONNECT")
  }

  _onClose(e) {

    console.log("MQTT CLOSE")
    console.log(e)
    /*notify(
      {
        group: 'workspace',
        level: 'warn',
        title: 'MQTT ',
        text: 'Cliente MQTT cerrado.',
      },
      2500 // 2.5s
    )*/
  }

  _onError(error) {
    console.error("MQTT ERROR", error);
    notify(
      {
        group: 'workspace',
        level: 'error',
        title: 'MQTT ',
        text: 'Error en cliente MQTT.',
      },
      2500 // 2.5s
    )
    const store = useStore();
    store.mqttDisconnected = true;
  }

  _onMessage(topic, message) {
    handleMessage(topic, message);
  }

  _onReconnect() {
    console.log("MQTT RECONNECT");
  }
}

export default new MQTTClient();
