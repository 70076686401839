import useStore from '@/store'

//const baseurl = "https://settings.alfa.cvis.videri.ai/"
//const baseurl = "https://settings.testcloud.mindlabs.cl/"
const baseurl = "https://settings.cvis.videri.ai/"

const request = async (url, method, headers, body) => {
    const username = localStorage.getItem("username");
    const password = localStorage.getItem("password");
    headers["username"] = username;
    headers["password"] = password;
    headers["content-type"] = "application/json";
    const body2 = body ? JSON.stringify(body): null;
    const response = await fetch(url, {
        method: method,
        headers: headers,
        body: body2
    });
    const data = await response.json();
    return data;
}

export const getDeviceSettings = async () => {
    const store = useStore();
    const deviceSn = store.child_device_sn;
    const url = baseurl + "settings/" + deviceSn;
    return await request(url, "GET", {}, null);
}

export const setDeviceSettingKey = async (key, value) => {
    const store = useStore();
    const deviceSn = store.child_device_sn;
    const url = baseurl + "settings/" + deviceSn + "/" + key;
    const body = {"value": value};
    return await request(url, "POST", {}, body);
}

export const getUserSettings = async () => {
    const url = baseurl + "settings/me";
    return await request(url, "GET", {}, null); 
}
