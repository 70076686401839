<!-- eslint-disable no-unused-vars -->
<template>
  <v-layout class="rounded rounded-md">
    <v-main
      class="align-center justify-center container"
      style="min-height: 300px"
    >
    <v-list width="80%" class="jobList" >
      <v-list-item v-for="job in jobsPage" :key="job.job_id">
            <task-box :job="job"></task-box>
      </v-list-item>
      <v-list-item>
        <v-pagination
              v-model="page"
              :length="this.pages"
            ></v-pagination>
      </v-list-item>
    </v-list>
  </v-main>
    

  </v-layout>

</template>

<script>
import TaskBox from "./TaskBox.vue";
export default {
  name: "TaskView",
  props: {
    jobs: Array,
  },
  components: {
    TaskBox,
  },
  setup() {
    return {};
  },
  async mounted() {
    this.jobs2 = JSON.parse(JSON.stringify(this.jobs));
    this.jobs2.sort((a, b) => a.execute_time.localeCompare(b.execute_time));
    this.jobs2.reverse();
    this.total = this.jobs2.length;
    this.pages = Math.ceil(this.total / this.pagesize);
    this.mounted = true;
  },
  data: () => ({
    total: 0,
    pages: 0,
    pagesize: 10,
    page: 1,
    today: new Date(),
    mounted: false,
    jobs2: [],
  }),
  computed: {
    jobsPage() {
      const start = (this.page - 1) * this.pagesize;
      const end = start + this.pagesize;

      return this.jobs2.slice(start, end);
    },
    //
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.jobList {
  background-color: #121212;
  height: 100%;
  width: 40%;
  align-items: top;
  justify-content: center;
  overflow-y: auto;
}
.bottom {
  position: fixed;
  bottom: 500;
  width: 100%;
  background-color: #121212;
}
</style>
