<!-- eslint-disable no-unused-vars -->

<template>
  <v-card variant="tonal">
    <v-card-title>{{ wayline.name }}</v-card-title>
    <v-card-text>
      <v-icon>mdi-account</v-icon> {{ wayline.user_name }} <br />
      <v-icon>mdi-calendar</v-icon> {{ new Date(wayline.update_time) }}
      <v-dialog v-model="dialog" width="auto">
        <v-card>
          <v-card-title>Eliminar Wayline: {{ wayline.name }}</v-card-title>
          <v-card-actions>
            <v-btn color="primary" @click="dialog = false">Cancelar</v-btn>
            <v-btn color="error" @click="deleteWayline">Eliminar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="showScheduleBox" persistent class="boxDialog">
        <v-card>
          <v-card-title>Agendar misión</v-card-title>
          <v-card-subtitle>
            <v-chip prepend-icon="mdi-home">
              {{ device.device_name }}
            </v-chip>
            <v-chip prepend-icon="mdi-identifier">
              {{ device.device_sn }}
            </v-chip>

            <v-chip prepend-icon="mdi-map">
              {{ wayline.name }}
            </v-chip>
          </v-card-subtitle>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-text-field v-model="scheduleModel.taskName" label="Nombre de la misión" type="text"
                    required></v-text-field>
                  <v-text-field v-model="scheduleModel.rthHeight" label="Altura de RTH" type="number"
                    :rules="[numberRule]" required></v-text-field>
                  <p>Fecha de ejecución</p>
                  <v-radio-group v-model="scheduleModel.inmediateExecution">
                    <v-radio label="Inmediata" :value="0"></v-radio>
                    
                      <v-radio :label="'Agendar: ' +
                      new Date(scheduleModel.executionDate).toLocaleString()
                      " :value="1"></v-radio>
                    
                  </v-radio-group>
                  <p>Acción de emergencia</p>
                  <v-radio-group v-model="scheduleModel.emergencyAction">
                    <v-radio label="Return To Home" :value="0"></v-radio>
                    <!--
                      <v-radio label="Hover" :value="1"></v-radio>
                      <v-radio label="Land" :value="2"></v-radio>
                    -->
                  </v-radio-group>
                </v-col>
                <v-col>
                  <div v-if="scheduleModel.inmediateExecution == 1">
                    <vc-date-picker v-model="scheduleModel.executionDate" mode="dateTime" is24hr :time-accuracy="3" />
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="showScheduleBox = false">Cancelar</v-btn>
            <!--<v-btn v-if="scheduleModel.rthHeight >= HMIN_CONFIG && scheduleModel.rthHeight <= HMAX_CONFIG" color="success" @click="getPlanBody">Agendar</v-btn>-->
            <v-btn v-if="agendable" color="success" @click="getPlanBody">Agendar</v-btn>
          </v-card-actions>

        </v-card>
        
      </v-dialog>
      
    </v-card-text>
    <v-dialog v-if="showScheduleConfirm">
        <v-card>
          <v-card-title>Seguro que desea confirmar la misión</v-card-title>
        </v-card>
      </v-dialog>
    <v-card-actions>
      <v-chip @click="downloadWayline" color="primary"><v-icon>mdi-download-circle</v-icon></v-chip>
      <v-chip @click="viewWayline" color="primary"><v-icon>mdi-eye-arrow-right</v-icon></v-chip>
      <v-chip @click="showScheduleBox = true" color="success"><v-icon>mdi-calendar-clock</v-icon></v-chip>
      <v-chip @click="dialog = true" color="error"><v-icon>mdi-delete-circle</v-icon></v-chip>
    </v-card-actions>
  </v-card>
</template>

<script>
//import TSAMap from "./components/tsa/TSAMap.vue";
//import TSATopBar from "./components/tsa/TSATopBar.vue";
//import useUiStore from '@/ui'
//import { storeToRefs } from "pinia";
/* eslint-disable */
// eslint-disable-next-line
import { CURRENT_CONFIG, HMIN_CONFIG, HMAX_CONFIG } from '../../../../api/http/config.ts';

import moment from "moment";
// eslint-disable-next-line no-unused-vars
import useStore from "@/store";
import {
  downloadWaylineService,
  deleteWaylineService,
} from "@/services/waylineService.js";
import { createPlanService } from "@/services/taskService.js";
import { downloadFile } from "@/utils/common.ts";
import router from "@/router";



export default {
  name: "WaylineBox",
  props: {
    wayline: Object,
  },
  components: {},
  setup() {
    const store = useStore();

    return {
      waylines: [],
      store,
      config: CURRENT_CONFIG,
      hmin: HMIN_CONFIG,
      hmax: HMAX_CONFIG,
    };
  },
  mounted() {
    this.devices = JSON.parse(localStorage.getItem("devices"));
    this.device = this.devices.find((device) => {
      return device.device_sn === this.store.device_sn;
    });
  },
  data: () => {
    return {
      dialog: false,
      showScheduleBox: false,
      showScheduleConfirm: true,
      scheduleModel: {
        taskName: "",
        wayline: "",
        device: "",
        inmediateExecution: 1 ,
        executionDate: new Date(),
        /*
        rthHeight: CURRENT_CONFIG.hmin,
        hmin: CURRENT_CONFIG.hmin,
        hmax: CURRENT_CONFIG.hmax,
        */
        rthHeight: HMIN_CONFIG.value,
        hmin: HMIN_CONFIG.value,
        hmax: HMAX_CONFIG.value,
        emergencyAction: 0,
      },
      numberRule: v => {
        if (!isNaN(parseFloat(v)) && v >= HMIN_CONFIG.value && v <=  HMAX_CONFIG.value) return true;
        return 'Altura RTH debe estar entre ' + HMIN_CONFIG.value + ' y ' +  HMAX_CONFIG.value + 'm';
      },
    };
  },
  methods: {
    async downloadWayline() {
      await downloadWaylineService(this.wayline.id).then((blob) => {
        if (blob !== null) {
          downloadFile(blob, this.wayline.name + ".kmz");
        } else {
          console.log("Error al descargar el archivo");
        }
      });
    },
    async deleteWayline() {
      console.log("deleteWayline");
      await deleteWaylineService(this.wayline.id).then((response) => {
        console.log(response);
        router.go();
      });
    },
    async viewWayline() {
      this.store.selectedWaylineObject = this.wayline;
      await downloadWaylineService(this.wayline.id).then((blob) => {
        this.store.selectedWayline = blob;
      });
      console.log("viewWayline");
    },
    toggleScheduleBox() {
      this.scheduleBox[this.wayline.id] = !this.scheduleBox[this.wayline.id];
    },
    async scheduleModalConfirmation() {

    },
    async getPlanBody() {
      if (this.scheduleModel.rthHeight < HMIN_CONFIG.value || this.scheduleModel.rthHeight > HMAX_CONFIG.value) {
        console.log("Altura RTH debe estar entre " + HMIN_CONFIG.value + " y " + HMAX_CONFIG.value + "m");
        return;
      } else {
        
        let planBody = {
          name: this.scheduleModel.taskName,
          file_id: this.wayline.id,
          dock_sn: this.store.device_sn,
          task_type: this.scheduleModel.inmediateExecution, // TaskType.Immediate,
          select_execute_time: moment(this.scheduleModel.executionDate),
          rth_altitude: Number(this.scheduleModel.rthHeight),
          out_of_control_action: this.scheduleModel.emergencyAction, //OutOfControlAction.ReturnToHome,
          wayline_type: this.wayline.template_types[0],
        };
        if (this.scheduleModel.inmediateExecution == 1) {
          planBody.task_days = [moment(planBody.select_execute_time).unix()];
          planBody.task_periods = [planBody.task_days];
        }

        const result = await createPlanService(planBody);
        if (result == true) {
          this.showScheduleBox = false;
        } else {
          console.log("Error al agendar la misión");
        }
        
      }
    },
  },
  computed: {
    agendable() {
      const validate_height = this.scheduleModel.rthHeight >=  HMIN_CONFIG.value && this.scheduleModel.rthHeight <= HMAX_CONFIG.value;
      return validate_height;
    },
    //
  },
  watch: {
    scheduleBox(newValue, oldValue) {
      console.log(newValue, oldValue);
    },
  },
};
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxDialog {
  height: 95%;
  width: 95%;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  overflow: auto;
  align-items: center;
  justify-content: center;
}
</style>
